<template>
	<div class="hx-settings">

		<div class="order">
			<div class="con" style="width: 80%;display: flex;justify-content: space-between;">
				<div class="conditions" align="left">
					<h2>个人信息</h2>
					<p>基础信息</p>
					<div align="center">
						<table >
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">姓名：</td>
								<td align="left">{{ruleForm11.dname}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">医师证书省份：</td>
								<td align="left">{{ruleForm11.cardNo}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">医师证书职业地点：</td>
								<td align="left">{{ruleForm11.credentialsAddr}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">手机号码：</td>
								<td align="left">{{oldPhone}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">昵称：</td>
								<td align="left">{{ruleForm11.webName}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">单位名称：</td>
								<td align="left">{{ruleForm11.company}}</td>
							</tr>
							<tr align="left" style="height: 40px;">
								<td style="text-align: right;">所在城市：</td>
								<td align="left">{{oldStateName}}</td>
							</tr>
						</table>
						<div style="margin-top: 20px;">
							<el-button class="nextStep" style="width: 140px;margin: 0px auto;margin-right: 50px;" type="primary"
								@click="modifyBaseInfo">修改基础信息</el-button>
							<el-button  class="nextStep" @click="modifyPwd" style="width: 140px;margin-left: 50px;">修改密码</el-button>
						</div>
					</div>
					<!-- <div align="center">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px"
							class="demo-ruleForm">
							<el-form-item label="医师姓名:" prop="name" style="text-align: left;">
								{{ruleForm.dname}}
							</el-form-item>
							<el-form-item label="医师证书省份:" prop="cardNo" style="text-align: left;">
								{{ruleForm.cardNo}}
							</el-form-item>
							<el-form-item label="医师证书职业地点:" prop="credentialsAddr" style="text-align: left;">
								{{ruleForm.credentialsAddr}}
							</el-form-item>
							<el-form-item label="手机号码:" style="text-align: left;">
								{{oldPhone}}
							</el-form-item>
							<el-form-item label="新手机号码:" prop="phone">
								<el-input v-model="ruleForm.phone"></el-input>
							</el-form-item>
							
							<el-form-item label="验证码:" prop="userCode">
								<el-input placeholder="请输入短信验证码" v-model="ruleForm.userCode">
									<template slot="suffix"><button :disabled="zaicifasong" type="button" id="c"
											@click="getCode">{{phoneCode}}</button></template>
								</el-input>
							</el-form-item>
							<el-form-item label="昵称:" prop="webName">
								<el-input v-model="ruleForm.webName"></el-input>
							</el-form-item>
							
							<el-form-item label="工作单位:" prop="company">
								<el-input v-model="ruleForm.company"></el-input>
							</el-form-item>
							<el-form-item label="所在城市:"  style="text-align: left;">
								{{oldStateName}}
							</el-form-item>
							<el-form-item label="新所在城市:" prop="stateName">
								<el-input v-model="ruleForm.stateName"></el-input>
							</el-form-item>
							<el-button class="nextStep" style="width: 120px;margin: 0px auto;" type="primary"
								@click="submitForm('ruleForm')">修改信息</el-button>
							<el-button  class="nextStep" @click="modifyPwd" style="width: 120px;">修改密码</el-button>
						</el-form>
					</div> -->
					<!-- <div align="center">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px"
							class="demo-ruleForm">
							<el-form-item label="新手机号码:" prop="phone">
								<el-input v-model="ruleForm.phone"></el-input>
							</el-form-item>
							
							<el-form-item label="验证码:" prop="userCode">
								<el-input placeholder="请输入短信验证码" v-model="ruleForm.userCode">
									<template slot="suffix"><button :disabled="zaicifasong" type="button" id="c"
											@click="getCode">{{phoneCode}}</button></template>
								</el-input>
							</el-form-item>
							<el-form-item label="昵称:" prop="webName">
								<el-input v-model="ruleForm.webName"></el-input>
							</el-form-item>
							
							<el-form-item label="工作单位:" prop="company">
								<el-input v-model="ruleForm.company"></el-input>
							</el-form-item>
							
							<el-form-item label="新所在城市:" prop="stateName">
								<el-input v-model="ruleForm.stateName"></el-input>
							</el-form-item>
							<el-button class="nextStep" style="width: 120px;margin: 0px auto;" type="primary"
								@click="submitForm('ruleForm')">修改信息</el-button>
							<el-button  class="nextStep" @click="modifyPwd" style="width: 120px;">修改密码</el-button>
						</el-form>
					</div> -->
				</div>
				<div class="right">
					<h2 align="left">收货地址信息</h2>
					<div align="left" style="overflow-y: auto; min-height: 145px; ">
						<div class="ress" align="left" v-for="address in doctorAddresses">
							<p>{{address.userName}},{{address.phone}}</p>
							<p>{{address.cityInfo}}</p>
							<p style="border-bottom: 1px solid lightgray;width: 100%;margin-bottom: 2px;"></p>
							<el-radio-group v-model="radio" @change="setDefaultChange">
								<el-radio v-if="address.isdefault==1" :label="address.id">已设为默认</el-radio>
								<el-radio v-if="address.isdefault==0" :label="address.id">设为默认</el-radio>
							</el-radio-group>
							<span style="cursor: pointer;padding-left: 25px;font-size: 14px;"
								@click="delAddress(address.id)">删除</span>
							<!-- <el-button size="mini" style="font-size: 12px;margin-left: 20px;height: 25px!important;line-height: 20px !important;border: none;color: #1196AB;" @click="delAddress(address.id)">删除</el-button> -->
						</div>
						<el-button class="nextStep" type="primary" @click="toNewAddress">新增收货地址</el-button>
						<el-button class="btj" v-if="isShow" @click="backTiJiao">返回订单</el-button>
					</div>
					<div align="left">
						<!-- <h2 @click="modifyPwd">修改密码</h2> -->
					</div>


				</div>
			</div>
		</div>

		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br />山东泰克莱恩 0513-88762550</div>
		<el-dialog @close="resetpwdForm('pwdForm')" title="修改密码" :visible.sync="dialogFormVisible" width="30%"
			top="20vh">
			<el-form :model="pwdForm" ref="pwdForm" :rules="pwdFormRules" style="margin: 0px auto;">
				<el-form-item label="旧密码" :label-width="formLabelWidth" prop="oldPassword">
					<el-input type="password" v-model="pwdForm.oldPassword" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
					<el-input type="password" v-model="pwdForm.password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" :label-width="formLabelWidth" prop="rePassword">
					<el-input type="password" v-model="pwdForm.rePassword" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button class="btnColor" type="primary" @click="modifyPwd('pwdForm')">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 添加地址的dialog -->
		<el-dialog style="z-index: 10;" title="收货地址" :visible.sync="dialogAddressFormVisible" width="40%"
			@close='resetDialogForms'>
			<el-form :model="addressruleForm" label-width="80px" ref="addressruleForm" :rules="addressrules">
				<el-form-item label="收货人" :label-width="formLabelWidth" prop="userName">
					<el-input v-model="addressruleForm.userName" autocomplete="off" placeholder="请输入收货人姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
					<el-input v-model="addressruleForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="省/市/城区" :label-width="formLabelWidth" prop="regionId" required>
					<el-cascader style="width: 30%;position: relative;left: -25px;" v-model="value" :options="sheng"
						:props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
					<el-cascader style="width: 30%;position: relative;left: -12.5px;" v-model="shiValue" :options="shi"
						:props="{ expandTrigger: 'hover' }" @change="handleChange1"></el-cascader>
					<el-cascader style="width: 30%;" v-model="quValue" :options="qu" :props="{ expandTrigger: 'hover' }"
						@change="handleChange2"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
					<el-input v-model="addressruleForm.address" autocomplete="off" placeholder="请输入详细地址"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="submitAddressForm('addressruleForm')">添加</el-button>
			</div>
		</el-dialog>
		<!-- 修改基础信息的dialog -->
		<el-dialog @close="resetpwdForm('ruleForm')" title="修改基础信息" :visible.sync="dialogModifyFormVisible" width="30%"
			top="15vh">
			<el-form :model="ruleForm" ref="ruleForm" :rules="rules" style="margin: 0px auto;" label-width="100px">
				<el-form-item label="新手机号码:" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					
					<el-form-item label="验证码:" prop="userCode">
						<el-input placeholder="请输入短信验证码" v-model="ruleForm.userCode">
							<template slot="suffix"><button :disabled="zaicifasong" type="button" id="c"
									@click="getCode">{{phoneCode}}</button></template>
						</el-input>
					</el-form-item>
					<el-form-item label="昵称:" prop="webName">
						<el-input v-model="ruleForm.webName"></el-input>
					</el-form-item>
					
					<el-form-item label="单位名称:" prop="company">
						<el-input v-model="ruleForm.company"></el-input>
					</el-form-item>
					
					<el-form-item label="新所在城市:" prop="stateName">
						<el-input v-model="ruleForm.stateName"></el-input>
					</el-form-item>
					<!-- <el-button class="nextStep" style="width: 120px;margin: 0px auto;" type="primary"
						@click="submitForm('ruleForm')">修改信息</el-button>
					<el-button  class="nextStep" @click="modifyPwd" style="width: 120px;">修改密码</el-button> -->
				</el-form>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogModifyFormVisible = false">取 消</el-button>
				<el-button class="btnColor" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			
			//验证验证码
			var checkValidateCode = (rule, value, callback) => {
				if (value != this.yzCode) {
					callback(new Error('验证码输入有误'));
				} else {
					callback();
				}
			}
			return {
				dialogModifyFormVisible:false,
				oldStateName:'',
				oldPhone:'',
				zaicifasong:false,
				btnIsDisabled:true,
				yzCode:'',
				si: '',
				phoneCode: "获取验证码",
				isShow: false,
				value: '',
				dialogAddressFormVisible: false,
				formLabelWidth: '80px',
				dialogFormVisible: false,
				radio: '',
				doctorAddresses: [],
				defaultAddress: '',
				qu: [],
				quValue: [],
				shi: [],
				shiValue: [],
				sheng: [],
				options: [], //存储选中的省市区
				addressruleForm: {
					doctorId: '',
					regionId: "",
					address: '',
					phone: '',
					userName: '',
					oid: '0'
				},
				addressrules: {
					userName: [{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					regionId: [{
						required: true,
						message: '请输入省市区',
						trigger: 'change'
					}],

				},
				ruleForm: {
					userCode: '',
					dname: '',
					id: '',
					status: '',
					regionId: '',
					phone: '',
					password: '',
					company: '',
					stateName: '',
					webName: '',
					credentialsAddr: '',
					cardNo: ''
				},
				ruleForm11: {
					userCode: '',
					dname: '',
					id: '',
					status: '',
					regionId: '',
					phone: '',
					password: '',
					company: '',
					stateName: '',
					webName: '',
					credentialsAddr: '',
					cardNo: ''
				},
				rules: {
					userCode: [
					  { required: true, message: '请输入验证码', trigger: 'blur' },
					  {
					  	min: 6,
					  	max: 6,
					  	message: "验证码为六位数字"
					  },
					  {
					  	validator: checkValidateCode,
					  	trigger: 'blur'
					  }
					],
					/* webName: [{
						required: true,
						message: '请输入网站昵称',
						trigger: 'change'
					}], */
					/* company: [{
						required: true,
						message: '请输入工作单位',
						trigger: 'change'
					}], */
					stateName: [{
						required: true,
						message: '请输入所在城市',
						trigger: 'change'
					}],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '长度为11个字符',
							trigger: 'blur'
						},
						
						/* ,
						{
							pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
							message: '手机号格式有误'
						} */
					]


				},
				pwdForm: {
					id: '',
					password: '',
					oldPassword: '',
					rePassword: ''
				},
				pwdFormRules: {
					oldPassword: [{
						required: true,
						message: '请输入原密码',
						trigger: 'change'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'change'
					}],
					rePassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'change'
					}],
				}
			}
		},
		methods: {
			//单击修改基础信息弹出对话框
			modifyBaseInfo(){
				this.aa();
				this.dialogModifyFormVisible=true;
			},
			//关闭dialog之后,将表单数据情况
			resetpwdForm(formName) {
				this.$refs[formName].resetFields();
				/* this.pwdForm.oldPassword = '';
				this.pwdForm.password = '';
				this.pwdForm.rePassword = ''; */
			},
			//获取验证码
			getCode() {
				if (this.ruleForm.phone == '') {
					this.$message({
						message: "请输入手机号",
						type: "warning",
						duration: 900
					});
				}else{
					this.zaicifasong=true;
					let num = 60;
					//this.phoneCode=num+"秒";
					this.si = setInterval(() => {
						let n = num--;
						if (n < 1) {
							//document.getElementById("c").removeAttribute("disabled");
							clearInterval(this.si);
							this.phoneCode = "获取验证码";
							this.yzCode = '';
							this.zaicifasong=false;
						} else {
							this.phoneCode = n + "秒";
						}
					}, 1000)
					this.$axios.get("/client/doctor/getUpdCode", {
						params: {
							phone: this.ruleForm.phone
						}
					}).then(res => {
						this.yzCode = res.data.data.resCode;
					}).catch(err => {
						this.$message.error("请联系管理员");
					});
				}
				
			},
			//返回订单
			backTiJiao() {
				this.$router.push("/hxmain/hxorder/hxorderten")
			},
			//新增收货地址
			//添加新的收货地址
			toNewAddress() {
				this.getShengShiQu();
				this.dialogAddressFormVisible = true;
			},
			//添加新地址
			submitAddressForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post("/client/doctor/addAddress", JSON.stringify(this.addressruleForm), {
							headers: {
								"Content-type": "application/json"
							}
						}).then(res => {
							if (res.data.code == 200) {
								this.$alert("添加地址成功", "提示");
								this.isShow = true;
								this.initForm();
								this.dialogAddressFormVisible = false;
								this.getAddressById();
							}
						}).catch(err => {
							this.$message.error("请联系管理员");
						});
					} else {
						return false;
					}
				});
			},
			//dialog关闭的时候清空表单内容
			initForm() {
				this.addressruleForm.address = '';
				this.addressruleForm.phone = '';
				this.addressruleForm.userName = '';
				this.value = [];
				this.shiValue = [];
				this.quValue = [];
			},
			//查询省市区
			getShengShiQu() {
				this.$axios.get("/queryNextLevelRegions", {
					params: {
						pid: 100000
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.sheng.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据省查询市
			handleChange(v) {
				//每次添加市之前先清空数组
				this.shi = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.shi.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据市查询区
			handleChange1(v) {
				//每次添加区之前先清空数组
				this.qu = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.qu.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//单击dialog的取消清空form
			cancel() {
				this.initForm();
				this.dialogAddressFormVisible = false;
			},
			//选中区之后给ruleForm中的cityInfo赋值
			handleChange2(v) {
				this.addressruleForm.regionId = v[0];
			},
			resetDialogForms() {
				// this.$refs.ruleForm.resetFields();
				this.$refs.addressruleForm.resetFields();
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var _this = this;
						this.$axios.post("client/doctor/updDoctorInfoSelf", JSON.stringify(this.ruleForm), {
							headers: {
								"Content-type": "application/json"
							}
						}).then(res => {
							if(res.data.code==200){
								/* this.ruleForm11.webName=this.ruleForm.webName;
								console.log(this.ruleForm11.webName);
								this.oldPhone=this.ruleForm.phone;
								this.oldStateName=this.ruleForm.stateName;
								this.ruleForm11.company=this.ruleForm.company; */
								this.aa();
								this.$alert('修改个人基础信息成功', '提示', {
									confirmButtonText: '确定',
									callback: action => {
										clearInterval(_this.si);
										_this.phoneCode = "获取验证码";
										_this.yzCode = '';
										_this.ruleForm.userCode = '';
										_this.$router.push("/hxmain/hxsettings");
									}
								});
								this.dialogModifyFormVisible=false;
							}else if(res.data.code==403){
								this.$alert("该手机号已被注册!");
							}
							
						}).catch(err => {
							this.$alert("请联系管理员", "提示");
						})
					} else {
						this.$alert('提交信息不完整或有误,请检查后再提交', "提示");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//设置默认地址
			isDefaultAddress(addrId) {
				//根据id查询医师个人中信息
				this.$axios.get("/client/doctor/setDefaultAddress", {
					params: {
						doctorId: this.ruleForm.id,
						addrId: addrId
					}
				}).then(res => {

				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			},
			//修改默认地址
			setDefaultChange(addressId) {
				this.radio = addressId;
				this.$axios.get("/client/doctor/setDefaultAddress", {
					params: {
						doctorId: this.ruleForm.id,
						addrId: addressId
					}
				}).then(res => {
					if (res.data.code == 200) {
						//查询所有地址信息
						this.$axios.get("/client/doctor/queryDoctorAddress", {
							params: {
								doctorId: this.ruleForm.id
							}
						}).then(res => {
							this.doctorAddresses = res.data.data.addressList;
							this.defaultAddress = res.data.data.defaultAddress;
							this.radio = this.defaultAddress.id;
						}).catch(err => {
							this.$alert("系统出现异常,请联系管理员!");
						});
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//删除地址
			delAddress(addrId) {
				this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//根据地址id删除地址
					this.$axios.get("/client/doctor/delAddress", {
						params: {
							addrId: addrId
						}
					}).then(res => {
						if (res.data.code == 200) {
							this.$alert('地址删除成功', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.getAddressById();
								}
							});
						}

					}).catch(err => {
						this.$alert("删除地址失败,请联系管理员", "提示");
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				// this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
				//           confirmButtonText: '确定',
				//           cancelButtonText: '取消',
				//           type: 'warning'
				//         }).then(() => {
				//          this.$axios.get("/client/doctor/delAddress", {
				//          	params: {
				//          		addrId: addrId
				//          	}
				//          }).then(res => {
				//          	if (res.data.code == 200) {
				//          		this.$alert(
				//          			 "删除成功",
				//          			"提示"
				//          		);
				//          		//查询所有地址信息
				//          		this.$axios.get("/client/doctor/queryDoctorAddress",{
				//          			params:{
				//          				doctorId:this.ruleForm.id
				//          			}
				//          		}).then(res=>{
				//          			this.doctorAddresses=res.data.data.addressList;

				//          		}).catch(err=>{
				//          			console.log(err);
				//          		});
				//          	}
				//          }).catch(err => {
				//          	console.log("请联系管理员");
				//          });
				//         }).catch(() => {
				//           this.$message({
				//             type: 'info',
				//             message: '已取消删除'
				//           });          
				//         });

			},
			//修改密码
			modifyPwd(formName) {
				this.dialogFormVisible = true;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//1.获取当前登录用户的密码
						let pwd = JSON.parse(sessionStorage.getItem("loginInfo")).password;
						if (pwd != this.pwdForm.oldPassword) {
							this.$alert("原密码输入有误", "提示");
						} else if (this.pwdForm.password != this.pwdForm.rePassword) {
							this.$alert("两次密码不一致", "提示");
						} else {
							
							this.pwdForm.id=JSON.parse(sessionStorage.getItem("loginInfo")).id;
							this.$axios.post("/client/doctor/updPwd", JSON.stringify(this.pwdForm), {
								headers: {
									"Content-type": "application/json"
								}
							}).then(res => {
								let _this = this;
								window.setTimeout(function() {
									_this.dialogFormVisible = false;
									_this.$router.push("/login");
								}, 500)

							}).catch(err => {
								this.$alert("请联系管理员", "提示");
							})
						}


					} else {
						// this.$alert('个人信息不完成',"提示");
						return false;
					}
				});
			},
			//关闭dialog之后,将表单数据情况
			resetpwdForm(formName) {
				this.$refs[formName].resetFields();
				this.pwdForm.oldPassword = '';
				this.pwdForm.password = '';
				this.pwdForm.rePassword = '';
			},
			//根据医生id查询它的地址信息
			getAddressById() {
				//根据id查询医师地址信息
				this.$axios.get("/client/doctor/queryDoctorAddress", {
					params: {
						doctorId: this.ruleForm.id
					}
				}).then(res => {

					this.doctorAddresses = res.data.data.addressList;

				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			},
			aa(){
				let id = JSON.parse(sessionStorage.getItem("loginInfo")).id;
				this.ruleForm.dname = JSON.parse(sessionStorage.getItem("loginInfo")).dname;
				if (id != "undefined" && id != '' && id != null && id != undefined) {
					this.ruleForm.id = id;
					this.addressruleForm.doctorId = id;
					//根据id查询医师个人中信息
					this.$axios.get("/client/doctor/initSettings", {
						params: {
							doctorId: this.ruleForm.id
						}
					}).then(res => {
						let doctorInfo = res.data.data.doctorInfo;
						this.ruleForm = doctorInfo;
						this.ruleForm11=doctorInfo;
						this.oldPhone=doctorInfo.phone;
						this.oldStateName=doctorInfo.stateName;
						this.doctorAddresses = res.data.data.doctorAddresses;
						for (var i = 0; i < this.doctorAddresses.length; i++) {
							if (this.doctorAddresses[i].isdefault == 1) {
								this.radio = this.doctorAddresses[i].id;
							}
						}
				
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					});
				}
			}

		},
		created: function() {
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
				document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
			let id = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			this.ruleForm.dname = JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			if (id != "undefined" && id != '' && id != null && id != undefined) {
				this.ruleForm.id = id;
				this.addressruleForm.doctorId = id;
				//根据id查询医师个人中信息
				this.$axios.get("/client/doctor/initSettings", {
					params: {
						doctorId: this.ruleForm.id
					}
				}).then(res => {
					let doctorInfo = res.data.data.doctorInfo;
					this.ruleForm = doctorInfo;
					this.ruleForm11=doctorInfo;
					this.oldPhone=doctorInfo.phone;
					this.oldStateName=doctorInfo.stateName;
					this.doctorAddresses = res.data.data.doctorAddresses;
					for (var i = 0; i < this.doctorAddresses.length; i++) {
						if (this.doctorAddresses[i].isdefault == 1) {
							this.radio = this.doctorAddresses[i].id;
						}
					}

				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			}
			/* document.getElementsByClassName("fun1")[0].setAttribute("class", "fun1 sub-menue1")
			document.getElementsByClassName("fun")[0].setAttribute("class", "fun sub-menue visible") */

			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/hxmain/hxorder/hxorderten");
		}
	}
</script>

<style scoped="scoped">
	.hx-settings {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		margin-bottom: 1.875rem;
		/* z-index: 0; */
	}

	h2 {
		color: #0D8EA4;
	}

	.conditions p {
		padding-left: 25px;
	}

	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
	}

	.order {
		position: relative;
		background-color: white;
		min-width: 1550px;
		/* min-width: 90%; */
		min-height: 31.75rem;
		/* margin-top: 16.75rem; */
		margin-top: 11rem;
		margin-bottom: 15.75rem;
		padding-top: 1.25rem;
		padding-bottom: 10px;
		display: flex;
		justify-content: center;
		overflow-x: auto;
	}



	/* 条件 */
	.conditions {
		box-sizing:border-box;
		background-color: #f0f0ee;
		border-radius: 15px;
		padding: 10px 20px 20px 20px;
		min-width: 420px;
		margin: 0px auto;
		/* border: 1px solid red; */ 
		position: relative;
		left: -100px;
	}
	

	.right {
		width: 500px;
		/* border: 1px solid blue; */
		min-height: 31.75rem;
		position: relative;

	}

	.ress {
		/* border: 1px solid red; */
		/* background-color: lightgray; */
		/* border-radius: 15px; */
		padding-left: 10px;
		height: 120px;

	}

	.ress p {}

	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}

	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}

	.btj {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}

	#c {
		width: 6.25rem;
		height: 39.15px;
		border: none;
		background: #0D8EA4;
		position: relative;
		right: -0.3125rem;
		color: white;
	}
	.btnColor{
		border-color: #0D8EA4 !important;
		background-color: #0D8EA4 !important;
	}
</style>
